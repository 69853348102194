// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Raleway:wght@400;800&display=swap')

$font-title: 'Raleway'
$font-text: 'Lato'
$theme-red: #c70000
$theme-faded-red: #f2c7c7
$theme-blue: #0d60be
$background: #eeeeee

@mixin theme-buttons
    button
        margin: 0px 5px
        padding: 5px 10px
        border-style: none
        background-color: $theme-red
        font-family: $font-text
        color: white
        width: 5em

.whitespace10
    padding: 10px
    
.whitespace20
    padding: 20px

.whitespace50
    padding: 50px