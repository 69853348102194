@use "./_utils" as u

#profile
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    @include u.theme-buttons

    .profile-image-set
        img
            width: 300px

    .user-info
        width: 300px
        margin-top: 20px
        display: flex
        justify-content: space-end

        .user-categories
            padding-right: 5px
            display: flex
            flex-direction: column
            align-items: flex-end
            border-right-style: solid
            border-width: 1px
            border-color: u.$theme-red

            p
                padding: 2px

        .user-values
            padding-left: 5px
            display: flex
            flex-direction: column
            align-items: flex-start

            p
                padding: 2px

    .profile-image-upload
        display: none
