@use "./_utils" as u

#curation-wrapper
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start

    .curation-search
        background-color: $theme-red
        margin: 0px 10px
        width: 80%
        display: flex
        justify-content: space-between
        align-items: center
        box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.3)
        -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.3)
        -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.4)

        #curation-searchbar-wrapper
            display: flex
            justify-content: flex-start
            width: calc(100% - 200px)

            #curation-searchbar
                margin: 10px 0px 10px 10px
                height: 1.8em
                border: 1px none white
                border-radius: 3px 0px 0px 3px
                box-shadow: inset 0px 0px 2px 1px rgba(0,0,0,0.3)
                width: calc(100% - 1.8em)

            #curation-searchicon
                margin: 10px 0px 0px 0px
                height: calc(1.8em - 3px)
                width: 1.8em
                background-color: #eee
                border-radius: 0px 3px 3px 0px
                box-shadow: inset 0px 0px 2px 1px rgba(0,0,0,0.3)
                cursor: pointer
        
        #curation-search-options-wrapper
            display: flex
            justify-content: center
            align-items: center
            width: 290px
            margin: 0px 10px 0px 10px
            background-color: $theme-faded-red
            height: calc(1.8em - 2px)
            border: 1px none $theme-faded-red
            border-radius: 3px

            p
                color: $theme-red
                font-weight: bold
                font-size: 0.7em

    .curation-display
        margin: 10px
        width: 80%
        display: flex
        flex-direction: column
        align-items: center

        .curation-image-container
            margin-bottom: 10px
            background-color: white
            position: relative
            display: flex
            flex-direction: column
            box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.3)
            -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.3)
            -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.4)

            img
                width: 100%

            .image-details
                display: none
                flex-direction: column
                align-items: flex-start
                justify-content: space-between
                position: absolute
                height: 100%
                width: 100%
                background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%)
            &:hover
                .image-details
                    display: flex

                .image-details-upper
                    padding: 10px 0px 10px 0px
                    width: 100%

                    .source-container
                        display: flex
                        justify-content: space-between
                        align-items: center
                        padding: 0px 5px

                        p
                            font-size: 0.8em
                            color: white
                            margin: 0 5px 0 5px

                        .twitter-icon
                            width: 25px
                            border-radius: 50%
                            margin: 0 5px 0 5px

                .image-details-lower
                    display: flex
                    justify-content: space-between
                    align-items: flex-end
                    width: 100%

                    .tag-container
                        display: flex
                        flex-wrap: wrap
                        justify-content: flex-start
                        padding: 5px 5px 5px 5px

                        .tag-name
                            height: 1em
                            font-size: 0.8em
                            font-weight: bold
                            color: white
                            padding: 2px 5px 2px 5px
                            margin: 2px 2px 2px 2px
                            background-color: #5bcc0f
                            border-radius: 3px

                    .status-container
                        padding: 5px 5px 5px 5px
                        svg
                            color: #808080
                            font-size: 1.2em

        #curation-load-more-button
            font-size: 0.8em
            color: white
            background-color: $theme-faded-red
            height: 2.5em
            width: 10em
            display: flex
            justify-content: center
            align-items: center
            border-radius: 4px
            &:hover
                background-color: $theme-red
            box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.1)
            -webkit-box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.1)
            -moz-box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.1)

        #curation-load-no-more
            font-size: 0.8em
            height: 2.5em
            width: 40%
            display: flex
            justify-content: center
            align-items: center
            border-top: 1px solid $theme-red
            margin-top: 10px

    .curated-modal-image-container
        position: fixed
        top: 30px
        z-index: 4
        display: flex
        flex-direction: row
        padding: 15px
        background-color: #eeeeee

        .curated-modal-image
            max-height: 90vh
            max-width: 90vh
            box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.3)
            -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.3)
            -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.4)

        .curated-modal-details-container
            max-height: 90vh
            width: 40vh
            display: flex
            flex-direction: column
            justify-content: space-between
            align-items: top
            margin-left: 15px

            .source-container
                display: flex
                justify-content: space-between
                align-items: center
                padding: 0px 0 0 5px

                p
                    font-size: 0.8em
                    font-weight: bold
                    color: black
                    margin: 0 5px 0 5px

                .twitter-icon
                    width: 25px
                    border-radius: 50%
                    margin: 0 0 0 5px

            .curation-modal-tag-wrapper
                display: flex
                width: 100%
                flex-direction: column
                align-items: flex-start

                .tag-container
                    display: flex
                    justify-content: flex-start
                    max-width: 100%
                    padding: 5px 5px 5px 5px

                    p
                        font-size: 0.9em
                        font-weight: bold
                        color: black
                        margin: 1px 5px 5px 5px

                    div
                        display: flex
                        justify-content: flex-start
                        flex-wrap: wrap
                        max-width: 100%

                        .tag-name-self
                            height: 1em
                            font-size: 0.8em
                            font-weight: bold
                            color: white
                            padding: 2px 5px 2px 5px
                            margin: 0px 0px 2px 2px
                            background-color: #5bcc0f
                            border-radius: 3px
                            svg
                                display: none
                                font-size: 0.8em
                                padding: 1px 0px 0px 3px
                                margin: 0px 0px 0px 0px
                                &:hover
                                    color: red

                            &:hover
                                padding: 2px 2px 2px 5px
                                svg
                                    display: inline-block
                        
                        .tag-name-others
                            height: 1em
                            font-size: 0.8em
                            font-weight: bold
                            color: white
                            padding: 2px 5px 2px 5px
                            margin: 0px 0px 2px 2px
                            background-color: #318CE7
                            border-radius: 3px

                .curation-modal-tag-text
                    margin-left: 10px
                    width: calc(100% - 20px)
                    height: 1.7em

            .status-container
                padding: 5px 5px 0px 5px

                div
                    display: flex
                    justify-content: flex-end
                    align-items: center

                    p
                        font-weight: bold

            .other-details-wrapper
                display: flex
                flex-direction: column
                align-items: flex-end
                margin-top: 10px
                
                button
                    margin: 0px 3px
                    padding: 5px 5px
                    border-style: none
                    border-radius: 5px
                    background-color: $theme-red
                    font-family: $font-text
                    color: white
                    width: 5em
                button:disabled
                    background-color: $theme-faded-red


            .curated-modal-details-credits
                display: flex
                justify-content: space-between
                align-items: flex-end
                
                svg
                    color: $theme-faded-red
                    font-size: 1.5em
                    &:hover
                        color: $theme-red

                p
                    font-weight: bold
                    font-size: 1em

    .curated-modal-background
        position: fixed
        top: 0
        z-index: 3
        width: 100%
        height: 100%
        background-color: rgba(0, 0, 0, 0.7)