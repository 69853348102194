@import "./_utils"
@import "./curation"
@import "./profile"

#root
    position: relative
    font-family: 'Lato'
    display: flex
    justify-content: center
    align-items: center
    height: 100%

    .App
        width: 100%
        height: 100%
        display: flex
        flex-direction: column
        align-items: center

        #out-main
            display: flex
            justify-content: center
            align-items: center
            width: 100%
            height: 100vh
            background-color: white

            #auth
                background-color: white
                justify-content: center
                display: flex
                flex-direction: column
                justify-content: flex-start
                align-items: center
                border: 1px solid #708eb5
                padding: 20px
                border-radius: 7px
                box-shadow: 0px 1px 2px 2px rgba(198, 198, 198, 1)

                h1
                    font-family: $font-title
                    font-size: 2em
                    padding: 20px
                    color: $theme-blue

                p.auth-notes
                    color: $theme-blue
                    font-size: 0.8em

                .auth-fields
                    display: flex
                    flex-direction: column
                    justify-content: center

                    label
                        align-self: flex-start
                        font-weight: bold
                        padding: 15px 0 5px 0

                    .auth-input
                        padding: 10px
                        border: 1px solid #708eb5
                        box-shadow: 0 0 15px 4px rgba(0,0,0,0.03)
                        border-radius: 7px
                        width: 200px

                button
                    margin: 15px
                    padding: 10px
                    border: 1px solid #b45f0f
                    background-color: #f0b411
                    color: white
                    font-weight: bold
                    box-shadow: 0 0 15px 4px rgba(0,0,0,0.03)
                    border-radius: 7px
                    width: 100%

            #private-message
                p
                    padding: 10px

        .topbar
            background-color: $theme-red
            width: 100%
            min-height: 5em
            display: flex
            justify-content: space-between
            align-items: center
            box-shadow: 0px 1px 2px 1px #686868
            z-index: 3
            position: relative

            h1
                margin-left: 50px
                color: white
                font-family: $font-title
                font-size: 2em
                font-weight: bold

            .user-display
                margin-right: 50px
                display: flex
                justify-content: space-between
                align-items: center
                background-color: white
                height: 2em
                border-radius: 2em

                .profile-image
                    margin: 0px 3px 0px 15px
                    height: 2em
                    border-radius: 50%

                #logout-link
                    margin: 0px 20px 0px 3px
                    color: $theme-red
                    font-size: 0.9em
                    font-weight: bold
                    &:hover
                        text-decoration: underline

        nav
            min-height: 2.5em
            width: 100%
            background-color: white
            box-shadow: 0px 2px 2px 1px #bdbdbd
            z-index: 2
            position: relative

            .nav-links
                height: 100%
                display: flex
                justify-content: space-around
                align-items: center

                .nav-block
                    a
                        text-decoration: none

        .main-wrapper
            background-color: $background
            display: flex
            width: 100%
            height: 100%

            main
                height: 100%
                width: 100%
                margin-top: 20px

@media (pointer:coarse)
    #root
        .App
            width: 100%
            .main-wrapper
                flex-direction: column
